import React, { Component } from 'react';
import '../styles/underDev.css';

class UnderDev extends Component {
    render() {
        return (
            <div className="under-container">
                <div className="container">
                    <h1>Page under construction. Sorry for the inconvenience..</h1>
                </div>
            </div>
        )
    }

}

export default UnderDev;