import React, { Component } from 'react'
import '../styles/home.css';
import '../styles/home.css';

class Home extends Component {
    render() {
        return (
            <div>
                <section id="home">
                    <div className="container">
                        <div className="row home-content">
                            <div className="col-md-6">
                                <h1>Sto. Rosario Hospital</h1>
                                <h1>WELCOME!</h1>
                            </div>

                        </div>

                    </div>

                </section>

            </div>
        )
    }
}
export default Home
